/* You can add global styles to this file, and also import other style files */
:root
{
    --navbar-color:#00000000;
    --border-color:#FF32BB;
    --text-color:#efefef;
    --link-color:#ff32bb;
    --background-color:#151515;
    --background-color-campus:#151515;
    --material-background-color:#00000040;
    --shadow:rgba(0,0,0,0.2);
    /*
    --hover-color:#37fff1;
    --secondary-color:#ff29f1;

    */

    --hover-color:#ff32bb;
    --btn-background:#ff457a50;
    --btn-background-active:#ff32bb;
    --btn-background-disabled:#b92889;
    --table-background-color: #483e46;
    --content-background-color: #40404090;
    --th-background-color: #6d4866;


    --primary-color:#ff32bb;
    --secondary-color:#FFD8E9;
    --tertiary-color:#610044;
    --back-color:#000000;
    --golazo-border: 2px solid var(--primary-color);

    --bs-btn-close-color: #ffffff!important;
}


input[type="radio"]{
    accent-color: var(--primary-color);
}


.anticon-spin,.anticon-spin::before{
    display:inline-block;
    animation:loadingCircle 1s infinite linear
}

@keyframes loadingCircle{
    100%{
        transform:rotate(360deg)
    }
}


.cdk-overlay-connected-position-bounding-box{
    position:absolute;
    z-index:1000;
    display:flex;
    flex-direction:column;
    min-width:1px;
    min-height:1px
}

.cdk-overlay-container{
    pointer-events:none;
    top:0;
    left:0;
    position:fixed;
    z-index:1000
}

.cdk-overlay-pane{
    position:absolute;
    pointer-events:auto;
    z-index:1000
}








body{
    background-image: url('assets/bk.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    min-height: 100vh;
}

body.login-background{
    backdrop-filter: none;
}


.g-border{
    border:var(--golazo-border);
    border-radius: 12px;
}

.pac-container{
    z-index: 10000 !important;
}


@font-face {
    font-family: raleway;
    src: url('assets/font.woff');
  }


/* FireFox*/
@supports not (backdrop-filter: blur(20px)) {
    :root{
        --material-background-color:#404040;
    }
    .navbar-app
    {
        background-color: #10101080;
    }

    .blur-content{
        background-color: var(--table-background-color);
    }
}

@media (prefers-reduced-transparency: reduce) {
    :root{
        --material-background-color:#404040;
    }
    .app-modal.show .modal-dialog{
        backdrop-filter: none;
    }
  }


.section{
    border: 1px solid var(--border-color);
    border-radius: 10px;
    padding: 0.75rem;
}


.badge-info{
    background-color: var(--btn-background);
    border: 1px solid var( --hover-color);
    color: white;
}

/*Base CSS*/
body
{
    font-family: raleway;
    color:var(--text-color);
    background-color: var(--background-color);
    
}




.alert
{
    padding: .25rem .75rem;
    margin-bottom: .5rem;
    border: 1px solid transparent;
    border-radius: 10px;
}

a
{
    color:var(--link-color);
    font-weight: 650;
}

a:hover{
    color:var(--hover-color);
    cursor: pointer;
}


/*Modals*/



.app-modal .modal-content
{

    background: var(--material-background-color);
    backdrop-filter: blur(14px);
    border-radius: 12px;
    text-align: center;
    font-size: 1.1rem;

}



.app-modal .modal-body
{
    max-height: calc(100vh - 23vh);
    overflow-y: auto;
}

::-webkit-scrollbar {
    width: 8px;
  }




  /* Track */
  ::-webkit-scrollbar-track {
    background-color:#00000000;

    transition: all ease 0.5s;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ffffff50;
    border-radius: 8px;
    transition: all ease 0.5s;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #ffffffBB;
  }


.app-modal.show .modal-dialog{
    border: 1px solid var(--border-color);
    border-radius: 20px;
    backdrop-filter: blur(20px);
}


.app-modal .modal-content .left
{
    text-align: left;
}


.no-backdrop{
    opacity: 0!important;
}

.app-modal.top-modal .modal-dialog
{
    top:30px;
    animation: modal_top_anim .3s ease-in-out;
}

.imagen-ht{
  width: 90%;
  height: 95%;
}

.full-screen .modal-dialog{
    width: calc(100% - 1.2rem);
    max-width: 1440px;
}



@keyframes modal_top_anim
{
    0%{
        top:-200px;
        opacity: 0;
    }
}

.app-modal.top-modal .modal-content
{

    z-index: 1029;
}

.app-modal .modal-footer
{
    border-color: var(--border-color);
    justify-content: space-around;

}

.app-modal .modal-footer.right
{
    justify-content: flex-end;
}

.top-modal.modal.fade .modal-dialog
{
    transition: transform .5s ease-in-out;
    transform: translate(0,-100%);
}
.top-modal.modal.show .modal-dialog {
    transform:none;
}

.message-modal.modal.show .modal-dialog{
    transform: translateY(-55%);
    top: 50%;
}

.message-modal.modal.fade .modal-dialog{
    top: 50%;
    transform: translateY(-65%);
}

.top-modal.modal.modal-static .modal-dialog {

    transform: translate(0,-10%);
}


.full-w .modal-dialog{
    margin-left: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    width: 100vw;
    max-width: 100vw;
    border-radius: 0px;
    height: calc(100vh - 57px);
}

.full-w .modal-content{
    border-radius: 0px;
    height: 100%;
}

.full-w .modal-body{
    max-height: calc(100vh - 56px - 56px);
}

/* Fondos */





.phone-overlay{
    height: 640px;
    width: 317px;
    position: absolute;

    background-image: url('assets/i13_frame.png');
}



.framed-img{
    width: auto;
    position: relative;
}

.framed-img img{
    position: relative;
    max-width: 92.22%;
    margin-left: 3.89%;
    margin-top: 1.2%;
    border-radius: 10%;
}

.frame{
    width: 100%;
    position: absolute;
    background-image: url('assets/i13_frame.png');
    height: 100%;
    left: 0;
    top: 0;
    background-size: contain;
    background-repeat: no-repeat;
}

/* Style the video: 100% width and height to cover the entire window */
#myVideo {
    width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

/*#myVideoPromocional {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 50%;
  min-height: 50%;
}*/
video {
  width: 90%;
  height: auto;
}
/**/

.app-label
{
    display: block;
    width: 100%;
    margin-top: 0.25rem;
}

.app-input
{
    display: block;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #eeeeee;
    background-color: transparent;
    padding: 4px 8px;
    color:white;
    transition: all ease-in-out 0.25s;
}

.app-input:hover{
    border-color: #ffffff;
    transition: all ease-in-out 0.25s;
}

.app-input:focus{
    border-color: var(--primary-color)!important;
}

.app-input:active{
    border-color: var(--primary-color)!important;
}

/* Listas */
.lista
{
      width: 100%;
      min-height: 300px;
      max-height: 680px;
      overflow-y: auto;
      background-color: #00000040;
      border-radius: 20px;
      border-bottom: 1px solid #00000080;
}

.lista h6{
    padding: 0.4rem;
    margin: 0px;
    transition: all 0.3s ease-in-out;
    border-bottom: 1px solid #10101070;
}

.lista h6:first-child{
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
}

.lista h6:last-child{
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
      border-bottom-color: transparent;
}

.lista h6:hover
{
      cursor:pointer;
      background-color: #ffffff40;
}


/* Helpers*/


.loading-bar
{
    width: 100%;
    height: 10px;
    background-color: #00000030;
}
.loading-bar::after{
    content: "";
    display: block;
    width: 30%;
    height: 100%;
    background: linear-gradient(90deg, rgba(0,0,0,0.1) 0%, rgba(255,255,255,0.25) 50%, rgba(0,0,0,0.1) 100%);
    position: relative;
    animation: side-to-side 1s ease-in-out 0s infinite alternate-reverse;
}

@keyframes side-to-side
{
    0%{
        left:0%;
    }
    100%{
        left: calc(100% - 30%);
    }
}

.container.view
{
    display: flex;
    flex-direction: column;
    height: calc(100vh - 86px);
}

img
{
    max-width: 100%;
}

.loader,.loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
    animation: appear-anim 0.25s;
}

.loader {
    margin: auto auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.3em solid rgba(255, 255, 255, 0.1);
    border-right: 1.3em solid rgba(255, 255, 255, 0.1);
    border-bottom: 1.3em solid rgb(255 255 255 / .1);
    border-left: 1.3em solid #ffffff;
    transform: translateZ(0);
    animation: load8 1.2s infinite cubic-bezier(0.35, 0.05, 0.42, 0.84);
    min-height: 0px !important;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.loader.inline
{
    margin:0;
    font-size: 2.5px;
    border-width: 4px;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


@keyframes appear-anim {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }


/* Navbar CSS*/
.navbar-app
{
    background-color: var(--navbar-color);
    color:#101010;
    animation: appear-anim 0.5s;
    z-index: 9999!important;
    max-height: 57px;
    backdrop-filter: blur(30px);
}

.navbar-app .navbar-nav
{
    flex-direction: row;
}

.navbar-app .navbar-nav li
{
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    transition: all ease-in-out 0.5s;
    border-radius: 10px;
}


.navbar-app a{
    font-size: 1.2rem;
    text-transform: uppercase;
}

.navbar-app li :hover{
    color: var(--hover-color);
}



@media only screen and (max-width: 980px) {
    .navbar-app a {
      font-size: 0rem;
    }

    .navbar-app a .ng-fa-icon{
        font-size: 1rem;
    }
  }





/*Estilos de la web input*/
.read-only{
    pointer-events: none;
}
.app-lista-checkbox
{
    width: 100%;
    padding: 0.25rem;
    background-color: #00000040;
    max-height: 120px;
    overflow: auto;
    border-radius: 5px;
}

.app-checkbox
{
    border-radius: 10px;
    display: flex;
    transition: all ease-in-out 0.25s;
    padding: 0.2rem;
    margin: 0.1rem;
}
input[type='checkbox']{
    accent-color: var(--primary-color);
}

.app-checkbox input[type='checkbox']{
    width: 25px;
    height: 25px;
    margin-right: 6px;
}

.app-checkbox label,input
{
    margin: 0px;
}


.app-checkbox:hover{
    background-color: #ffffff20;
}


.app-btn-search{
    background: #101010;
    border: 1px solid #101010;
    color: white;
}

.app-btn
{
    text-decoration: none;
    transition: all ease .5s;
    border-radius: 4px;
    padding: 8px 16px;
    font-weight: bold;
    border:1px solid var(--primary-color);

    background-color: var(--primary-color);
    color:white;
}

.app-btn.secondary{
    background-color: var(--secondary-color);
    border:1px solid var(--secondary-color);
}

.app-btn.outline{
    background-color: transparent;
    color:white;
}

.app-btn.outline.secondary{
    color:var(--secondary-color);
}

.app-btn.rd{
    border-radius: 25px;
    padding: 4px 8px;
}


.app-btn.small
{
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    margin-bottom: 0.05rem;
    margin-right: 2px;
    font-size: 0.9rem;
}



.app-btn.md{
    width: 64px;
}





.botones-opciones
{
    width: 100%;
    display: flex;
    align-content: space-between;

}

.botones-opciones .app-btn
{
    border-radius: 25px;
    padding:2px 8px;
    margin-right:8px;
}

.botones-opciones.full .app-btn
{
   flex:auto;
}





.app-btn.red
{
    color:#ffc1c1;
}

.app-btn.green
{
    color:#c4ffa8;
}

.app-btn:focus
{
    outline: #ffffff90;
}

.app-btn:hover,.app-btn::selection
{
    text-decoration: none;
    box-shadow: 0 0 1px var(--primary-color), 0 0 3px var(--primary-color), 0 0 4px var(--primary-color), 0 0 15px var(--primary-color);
    transition: all ease-in-out 0.25s;
}

.app-btn:active,.app-btn.active
{
    background-color: var(--btn-background-active);
}



.app-btn:disabled
{
    background-color: var(--btn-background-disabled);
    border:1px solid var(--hover-color);
    cursor:not-allowed;
}

.app-btn.secondary:disabled
{
    background-color: transparent;
    border:1px solid var(--secondary-color);
    cursor:not-allowed;
}

.app-btn.circle
{
    border-radius: 50%;
    padding: 0px;
    width: 28px;
    height: 28px;
}

/* Combo*/

.app-combo
{
    width: 100%;
    margin: .5rem 0rem;
    border: 1px solid var(--secondary-color);
    background-color: transparent;
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 10px;
    transition: all ease-in-out 0.25s;
}

.app-combo:hover{
    background: var(--tertiary-color);
    border-color: white;
    transition: all ease-in-out 0.25s;
}


.app-combo.pink{

    color: white;
    margin: 0.1rem 0.3rem;
    text-decoration: none;
    border: 1px solid var(--primary-color);
    background: var(--primary-color);
    transition: all ease-in-out 0.25s;
}

.app-combo.pink:hover{
    border-color: white;
    transition: all ease-in-out 0.25s;
}

.blur-content{
    background-color: var(--content-background-color);
    backdrop-filter: blur(25px);
}



/* elementos */

input:focus,button:focus,select:focus{
    outline: none;
    animation: glow-anim .75s linear infinite alternate-reverse;
}

@keyframes glow-anim
{
    0%{
        border-color: #ffffffbb;
    }
    100%{
        border-color: #ffffff40;
    }
}

/*Otros*/
.skeleton {
    height: 100%;
    min-height: 1rem;
    animation: loading 0.8s infinite;
    animation-direction: alternate;
    border-radius: 5px;
    opacity: 0.5;
}
.skeleton.w50
{
    width: 50%;
}
.skeleton.w25
{
    width: 25%;
}
.skeleton.w75
{
    width: 75%;
}


/* Loading Animation */
@keyframes loading {
    0%{
        background-color: #ffffff;

    }
    100% {
        background-color: #858383;
    }
}

/********
Web
****/


.navbar-web{
    backdrop-filter: none;
    padding-top: 2rem;
    animation: margin_from_top 1s ease-in-out;
}

@keyframes margin_from_top{

    0%{
        margin-top:-100%;
    }
}

.fade-to-appear
{
    animation: appear-anim 1.2s ease-in-out;
}

.navbar-web .navbar-nav{
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}

.navbar-web .logo-web{
    max-width: 244px;
}

.navbar-web .navbar-nav li
{
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    transition: all ease-in-out 0.5s;
    border-radius: 10px;
}



.web-btn{
    background: #fff;
    color: black;
    border-radius: 35px;
    min-width: 180px;
    text-align: center;
}

.tituloinicio {

  color: #f1f1f1;
  width: 100%;
  /*padding: 20px;*/
  text-align: center;

}

@media (min-width: 1366px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1300px;
    }
}

@media (min-width: 1700px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1650px;
    }
}


.clip-status{
    position: fixed;
    left:4px;
    bottom:4px;
    z-index: 9999999;
    background-color: #101010D0;
    border-radius: 20px;
    border: 1px solid rgba(0,0,0,.2);
    padding: 8px;

}


.app-table{
    color:white;
    width: 100%;

    margin-bottom: 4px;
}

.app-table.ktable tr{
    display: none;
}


.table-container{
    height: 100%;
    overflow-y: scroll;
}

.ciclos-border > .table-container{
    height: calc(100vh - 296px);
}



.notification-center{
    position: fixed;
    right: 0px;
    bottom: 0px;
    z-index: 99999;
    padding: 16px;
}

.notification-center .cola{
    width: 350px;
}

.notification{
    background-color: #00000080;
    backdrop-filter: blur(10px);
    color:white;
    padding:8px;
    padding-right: 22px;
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.closef{
    position: absolute;
    right: 8px;
}


/*Tablas app-table*/

.app-table td{

    padding: 8px;
    border-bottom: var(--secondary-color) 1px solid;
}



.app-table > tbody > tr:first-child > td {
    border-top: 1px solid var(--secondary-color);



}

.app-table > tbody > tr:last-child > td {
    border-bottom: 1px solid var(--secondary-color);


}

.app-table tr:hover, .app-table tr.selected{
    background-color: #ffffff20;

}

/*******************

table.app-table {
    border: 1px solid var(--secondary-color);
    border-collapse: separate;
    border-left: 0;
    border-radius: 10px;
    border-spacing: 0px;
}
.app-table thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
    border-collapse: separate;

}


.app-table tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}
.app-table th, td {
    padding: 5px 4px 6px 4px;
    text-align: left;
    vertical-align: top;
    border-left: 1px solid var(--secondary-color);
}
.app-table td {
    border-top: 1px solid var(--secondary-color);
}
.app-table thead:first-child tr:first-child th:first-child, tbody:first-child tr:first-child td:first-child {
    border-radius: 10px 0 0 0;
}
.app-table thead:last-child tr:last-child th:first-child, tbody:last-child tr:last-child td:first-child {
    border-radius: 0 0 0 10px;
}

/****************************************************/


.ciclos-border{
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    padding: 32px 12px;
}

.botones-opciones.ciclos{
    padding-left: 8px;
    padding-right: 8px;
    flex-wrap: wrap;
}

.botones-opciones.ciclos > .app-btn{
    background-color: transparent;
    margin-bottom: 0px;
    margin-bottom: 16px;
}

.botones-opciones.ciclos > .app-btn.active{
    background-color: transparent;
    border-bottom: 1px solid var(--back-color);
    margin-bottom: -1px;
    padding-bottom: 18px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}


.lista > .app-input{
    background-color: #ffffff1a;
}

.profile{
    border-radius: 50%;
    width: 64px;
    height: 64px;
    object-fit: cover;

}


td .app-btn{
    margin-right: 4px;
}


.loader-modal .modal-dialog{
    width: 100%;
}

.loader-modal .modal-dialog .modal-content{
    background-color: #00000040;
    backdrop-filter: blur(5px);
    width: 100%;
}

app-loader{
    display: flex;
    justify-content: center;
}


.g-table{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.g-table-headers{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.g-table-body{
    width: 100%;
    flex:1;
    display: flex;
    flex-direction: column;
}

.g-table-row{
    width: 100%;
    display: flex;
    justify-content: space-between;

}

.g-table-col{
    flex:1;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
}




@media (max-width: 576px) {
    aside {
      display: none;
    }

    aside img{
        display: none;
    }

    app-menu.show aside{
        display: block;
        position: fixed;
        top:70px;
        left:0px;
        width: 100vw;
        height: calc(100vh - 70px);
        z-index: 10000;
        background-color: var(--material-background-color);
        backdrop-filter: blur(5px);
    }

    .item a{
        font-size: 16px!important;
    }

    .buttons-static-left{
        display: block!important;
    }
}



.pillow{
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    border-radius: 20px;
    padding: 2px 8px;
}

.pillow.gray,.pillow.disabled{
    background-color: var(--btn-background-disabled);
}